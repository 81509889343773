export interface AttributeValue {
  value: string;
  quantity: number;
}

export interface DynamicBottomModal {
  success: boolean;
  duration: number;
  title: string;
  minor?: string;
  subTitle: string;
}

export interface IonSelectValueChange {
  detail: { value: string; };
}

export type LanguagesInUse = 'de' | 'en';

export interface DefinedSettings {
  darkmode: boolean;
  language: LanguagesInUse;
  showTicketRedemptions: boolean;
  timeQuickRedemption: string;
  timeSingleRedemption: string;
  hasSeenIntroduction: boolean;
  askForCancellations: boolean;
  lastSearchVouchersValidForMs: string;
  showReservationInformation: boolean;
  displayRelevantTickets: boolean;
  cacheOfflineEvents: boolean;
  offlineMode: boolean;
  isOffline: boolean;
  timeBeforeOfflinePopup: number;
}

export enum SearchMode {
  CODE = 'code',
  ORDER = 'order',
  RESERVATION = 'reservation'
}
