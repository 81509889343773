import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EventDate} from '../../../../../../../src/app/components/pages/event-management/event-management.model';
import {Reservation} from '../../../../models/reservation.model';
import {ReservationService} from '../../../../services/reservation.service';
import {IonItemSliding, Platform} from '@ionic/angular';
import {UIFactoryService, UIType} from '../../../../services/ui-factory.service';
import {ReservationCheckDetailsComponent} from '../reservation-check-details/reservation-check-details.component';
import {getPlatform} from '../../../../utilities/helper/utils';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '../../../../services/settings.service';
import {Subscription} from 'rxjs';
import {DefinedSettings} from '../../../../models/common';
import {ScanService} from "../../../../services/scan.service";

@Component({
  selector: 'app-reservation-check-all',
  templateUrl: './reservation-check-all.component.html',
  styleUrls: ['./reservation-check-all.component.scss'],
})
export class ReservationCheckAllComponent implements OnInit, OnDestroy {

  @Input()
  public reservations: Array<Reservation>;

  @Input()
  public reservationNr: string;

  public eventDates: Array<Partial<EventDate>> = [];
  public settings: DefinedSettings;
  private subscriptions: Subscription = new Subscription();

  get checked(){
    return this.reservations.filter(r => r.checked).length;
  }

  constructor(
    public translationService: TranslateService,
    private reservationService: ReservationService,
    private uiFactory: UIFactoryService,
    private platform: Platform,
    private settingsService: SettingsService,
    private scanService: ScanService
  ) {
    this.subscriptions.add(this.settingsService.settings$.subscribe(settings => this.settings = settings));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
   await this.sortDate();
  }

  private async sortDate() {
    for (const reservation of this.reservations) {
      if (this.eventDates.find(e => e.id === reservation.eventDateId) === undefined) {
        this.eventDates.push({id: reservation.eventDateId, name: reservation.name, start: reservation.eventDateStart, slotStart: reservation.slotStart});
      }
    }
  }

  async onRefresh() {
    this.reservations = await this.reservationService.findReservationsByReservationId(this.reservationNr);
    await this.sortDate();
  }

  public async handleCheck(slider: IonItemSliding, reservation: Reservation) {
    await this.reservationService.updateReservationStatus(reservation);
    await this.onRefresh();
    await slider.close();
  }

  public getDisplayData(ownerData: any): string {
    let displayData = '';
    if (ownerData) {
      for (const key of ownerData) {
        if (key.value.length > 15) {
          displayData += key.value.substr(0, 15) + '... ';
        } else {
          displayData += key.value + ' ';
        }
      }
    }
    return displayData;
  }

  public async openSlider(slider: IonItemSliding, side: 'start' | 'end') {
    await slider.open(side);
  }

  public async showReservationDetail(reservation: Reservation, slider?: IonItemSliding) {
    if (slider) {
      await slider.close();
    }

    this.uiFactory.build(UIType.MODAL, {
      component: ReservationCheckDetailsComponent,
      canDismiss: true,
      showBackdrop: true,
      backdropDismiss: true,
      mode: 'ios',
      cssClass: 'inc-modal',
      presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
      componentProps: {
        reservationId: reservation.id,
      },
    }).then(t => t.present());
  }

  public async checkInAll(eventDateId) {

    const reservations = this.reservations.filter(r => r.eventDateId === eventDateId && !r.checked);
    for (const reservation of reservations) {
      await this.reservationService.updateReservationStatus(reservation);
    }
    await this.onRefresh();
  }

  public fullyChecked(eventDateId) {
    return this.reservations.filter(r => r.eventDateId === eventDateId && !r.checked).length === 0;
  }

  public async newScan() {
    await this.scanService.scanCode();
  }
}
