export let VOUCHER_CODE_NOT_FOUND = 101;
export let VOUCHER_FULLY_REDEEMED = 0;
export let VOUCHER_CANCELED = 103;
export let REDEEM_AMOUNT_TOO_LOW = 104;
export let REDEEM_AMOUNT_TOO_HIGH = 105;
export let VOUCHER_EXPIRED = 106;
export let VOUCHER_NOT_PAYED = 107;
export let VOUCHER_NOT_VALID_FOR_THIS_STATION = 108;
export let INVALID_PIN_CODE = 109;

export let BUNDLE_ARTICLE_NOT_FAST_REDEEMABLE = 99; //TODO-VS solve this in BE Validate Errors
