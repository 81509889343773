import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import {DefinedSettings} from '../../models/common';
import {Subscription} from 'rxjs';
import {ConnectionService} from '../../services/connection.service';
import {
  EventManagementSyncService
} from '../../../../../src/app/components/pages/event-management/event-management-sync.service';


@Component({
  selector: 'app-inc-offline-header',
  templateUrl: './inc-offline-header.component.html',
  styleUrls: ['./inc-offline-header.component.scss'],
})
export class IncOfflineHeaderComponent implements OnInit, OnDestroy {

  public settings: DefinedSettings;

  private subscriptions: Subscription = new Subscription();
  private _queueLength: number;

  public get queueLength() {
    return this._queueLength;
  }

  constructor(
    public settingsService: SettingsService,
    public connectionsService: ConnectionService,
    public syncService: EventManagementSyncService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.settingsService.settings$.subscribe(settings => {
      this.settings = settings;
    }));

    this.subscriptions.add(this.syncService.queuedTickets.subscribe(queue => {
      this._queueLength = queue.length;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleIsOffline(e) {
    if (this.settings?.offlineMode) {
      this.settingsService.settings$.next({
        ...this.settingsService.settings$.getValue(),
        isOffline: e.detail.checked
      });
    }
    this.connectionsService.checkConnection();
  }
}
