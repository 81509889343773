import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {
  CustomerCard,
  VoucherChargeRange,
  VoucherStatus
} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {CardActivateErrorMapper} from '../../../../../../shared-libs/lib-core/src/utilities/mapper/card.activate.error.mapper';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {
  CUSTOMER_CARD_NOT_ASSIGNED,
  VOUCHERCODE_NOT_ACTIVATED
} from '../../../../../../shared-libs/lib-core/src/models/status/voucher.status.constants';
import {ChargeCardResponse} from '../../../../../../shared-libs/lib-core/src/models/voucher';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-redeem-charge',
  templateUrl: './redeem-charge.component.html',
  styleUrls: ['./redeem-charge.component.scss'],
})
export class RedeemChargeComponent implements OnInit, OnDestroy {

  constructor(
    private t: TranslateService,
    private uiManager: UIManagerService,
    private redemptionService: RedemptionService,
    private formBuilder: FormBuilder,
    private redeemService: RedemptionService,
    private uiFactoryService: UIFactoryService) {

    this.subscriptions.push(this.redeemService.voucher$.subscribe(v => {
      if (v) {
        this.voucher = v;
      }
    }));
  }

  @Input() config: RedemptionConfig;
  @Input() isNew: boolean;

  public voucher: VoucherStatus;
  public chargeForm: FormGroup;
  public chargeConfig: VoucherChargeRange;
  public customerCard: CustomerCard;

  private subscriptions: Subscription[] = [];
  public isPermissionToChargeSet: boolean;
  public isCancelledCard: boolean;
  public isFullyCharged: boolean;
  public isInitialized: any;

  protected readonly Number = Number;

  async ngOnInit() {
    this.initChargeForm();
    this.chargeConfig = await this.redemptionService.getChargeValue(this.voucher.Code);
    this.customerCard = await this.redemptionService.fetchCardDetails(this.voucher.Code);

    if (this.customerCard.cardStatus === CUSTOMER_CARD_NOT_ASSIGNED) {
      this.isPermissionToChargeSet = false;
    } else if (this.config.ChargeGiftcard && this.config.ChargeCardPermission) {
      this.isPermissionToChargeSet = true;
    }

    if (this.voucher.Status === 2) {
      this.isCancelledCard = true;
    }

    if (this.voucher.CurrentAmount === this.chargeConfig.max_value) {
      this.isFullyCharged = true;
    }

    this.isInitialized = true;
  }

  private initChargeForm(): void {
    this.chargeForm = this.formBuilder.group(
      {
        Amount: ['', Validators.required],
        BookingID: '',
        ChargeBonusPoints: '',
        Name: '',
        Firstname: '',
        Lastname: '',
        EmailAddress: '',
        StreetAddress: '',
        City: '',
        PostCode: '',
        Country: '',
        Telephone: '',
        DOB: '',
        Company: '',
        Gender: ''
      }
    );
  }

  public async charge(): Promise<void> {

    if (this.isNew) {
      this.chargeForm.get('Amount')?.setValue(0);
    }

    if (!this.chargeForm.valid && this.chargeConfig.min_charge_value !== this.chargeConfig.max_value ) {
      const alert = await this.uiFactoryService.build(UIType.ALERT, {
        header: await this.t.get('main-detail.charge.missingValueTitle').toPromise(),
        subHeader: await this.t.get('main-detail.charge.missingValueSubTitle').toPromise(),
        buttons: [
          await this.t.get('main-detail.charge.okButton').toPromise(),
        ]
      });

      await alert.present();

      return;
    }

    if (this.voucher.Status === VOUCHERCODE_NOT_ACTIVATED) {
      const missingValues: string[] = [];

      // check if values are set
      if (this.config.ChargeGiftcard.PromptBonuspoints) {
        if (!this.chargeForm.get('ChargeBonusPoints')?.value) {
          missingValues.push(
            await this.t.get('main-detail.charge.bonusPointLabel').toPromise()
          );
        }
      }

      if (this.config.ChargeGiftcard.PromptGender) {
        if (!this.chargeForm.get('Gender')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.genderLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptFirstname) {
        if (!this.chargeForm.get('Firstname')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.firstnameLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptLastname) {
        if (!this.chargeForm.get('Lastname')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.lastnameLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptEmailAddress) {
        if (!this.chargeForm.get('EmailAddress')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.emailLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptPostCode) {
        if (!this.chargeForm.get('PostCode')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.plzLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptCity) {
        if (!this.chargeForm.get('City')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.cityLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptCountry) {
        if (!this.chargeForm.get('Country')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.countryLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptDOB) {
        if (!this.chargeForm.get('DOB')?.value) {
          missingValues.push(await this.t.get('main-detail.charge.birthdayLabel').toPromise());
        }
      }

      if (this.config.ChargeGiftcard.PromptCompany) {
        if (!this.chargeForm.get('Company')) {
          missingValues.push(await this.t.get('main-detail.charge.companyLabel').toPromise());
        }
      }

      if (missingValues.length !== 0) {

        let message = await this.t.get('main-detail.charge.missingFieldsText').toPromise();

        missingValues.forEach((val, index) => {
          if (index !== 0) {
            message += ', ';
          }

          message += val;
        });

        const alert = await this.uiFactoryService.build(UIType.ALERT,
          {
            header: await this.t.get('main-detail.charge.missingFieldsCaption').toPromise(),
            subHeader: message,
            buttons: [
              await this.t.get('main-detail.charge.missingFieldsButton').toPromise()
            ]
          }
        );
        await alert.present();

        return;
      }
    }

    const chargeFormValue = this.chargeForm.value;

    if (this.chargeConfig.max_value === this.chargeConfig.min_charge_value) {
      chargeFormValue.Amount = this.chargeConfig.max_value;
    }

    // before chargeCard - check if special login-dev is required
    if (this.config.CardChargeNeedsLogin) {
      const alert = await this.uiFactoryService.build(UIType.ALERT, {
        header: await this.t.get('main-detail.charge.needsLoginTitle').toPromise(),
        subHeader: await this.t.get('main-detail.charge.needsLoginMessage').toPromise(),
        inputs: [
          {
            name: 'user',
            placeholder: await this.t.get('main-detail.charge.needsLoginUsernameInput').toPromise(),
            value: ''
          },
          {
            name: 'password',
            type: 'password',
            placeholder: await this.t.get('main-detail.charge.needsLoginPassword').toPromise()
          }
        ],
        buttons: [
          {
            text: await this.t.get('main-detail.charge.needsLoginOkButton').toPromise(),
            handler: data => {
              chargeFormValue.LoginUser = data.user;
              chargeFormValue.LoginPassword = data.password;

              this.chargeCard(chargeFormValue);
            }
          },
          {
            text: await this.t.get('main-detail.charge.needsLoginAbortButton').toPromise(),
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });

      await alert.present();

    } else {
      await this.chargeCard(chargeFormValue);
    }
  }

  private async showLoader(): Promise<HTMLIonLoadingElement> {
    const loader = await this.uiFactoryService.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});

    await loader.present();

    return loader;
  }

  protected async chargeCard(chargeValue: any) {
    const loader = await this.showLoader();
    let result: ChargeCardResponse = null;

    try {
      result = await this.redeemService.chargeVoucherCard(this.config, this.voucher, chargeValue);
    } catch (e) {
      await this.uiManager.showHTTPConnectionErrorWhileFetching();
      await loader.dismiss();

      return;
    }

    await loader.dismiss();

    if (result.Success) {
      const newVoucher: VoucherStatus = await this.redeemService.fetchVoucher(this.voucher.Code);

      this.redeemService.voucher$.next(newVoucher);

      this.chargeForm.reset();

      await this.showChargeSuccessAlert();
    } else {
      await loader.dismiss();
      await this.showChargeErrorAlert(result);
    }
  }

  private async showChargeSuccessAlert() {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('main-detail.charge.successFullChargeCaption').toPromise(),
      subHeader: await this.t.get('main-detail.charge.successFullCharge').toPromise(),
      buttons: [
        await this.t.get('main-detail.charge.successFullChargeButton').toPromise(),
      ]
    });

    await alert.present();
  }

  private async showChargeErrorAlert(result: ChargeCardResponse) {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('main-detail.charge.errorChargeCaption').toPromise(),
      subHeader: await CardActivateErrorMapper.getMessage(this.t, result.Error.ErrorCode),
      buttons: [
        await this.t.get('main-detail.charge.errorChargeButton').toPromise(),
      ],
      cssClass: 'alert-error'
    });

    await alert.present();
  }


  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
