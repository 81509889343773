import {Component, OnDestroy, OnInit} from '@angular/core';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {SettingsService} from '../../../../../../shared-libs/lib-core/src/services/settings.service';
import {CameraUnavailableError} from '../../../../../../shared-libs/lib-core/src/errors/camera-unavailable.error';
import {ScanningAbortedError} from '../../../../../../shared-libs/lib-core/src/errors/scanning-aborted-error';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {DefinedSettings} from '../../../../../../shared-libs/lib-core/src/models/common';
import {Route} from '../../../../../../shared-libs/lib-core/src/models/page.interface';
import {Subscription} from 'rxjs';
import {AppIcon} from '../../../../../../shared-libs/lib-core/src/models/app-icon';
import {ReservationUiHelperService} from '../../../../../../shared-libs/lib-core/src/modules/reservation/reservation.ui-helper.service.ts.service';
import {ScanService} from '../../../../../../shared-libs/lib-core/src/services/scan.service';
import {CacheService} from '../../../../../../shared-libs/lib-core/src/services/cache.service';
import {isArray} from 'rxjs/internal-compatibility';
import {EventManagementSyncService} from '../../event-management/event-management-sync.service';
import {CachedVoucher} from '../../../../../../shared-libs/lib-core/src/models/cachedVoucher.model';

@Component({
  selector: 'app-main-scan-code-modal',
  templateUrl: 'main-scan-code-modal.component.html',
  styleUrls: ['main-scan-code-modal.component.scss'],
})
export class MainScanCodeModalComponent implements OnInit, OnDestroy {
  private settings: DefinedSettings;
  public config: RedemptionConfig;
  private subscriptions: Subscription[] = [];


  public get quickRedemptionInConfig() {
    return ('QuickRedemption' as keyof RedemptionConfig) in this.config;
  }

  constructor(
    private connectionService: ConnectionService,
    private redemptionService: RedemptionService,
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private settingsService: SettingsService,
    private platform: Platform,
    private translateService: TranslateService,
    private httpCachedClient: HttpCachedClient,
    private router: Router,
    private reservationUIHelper: ReservationUiHelperService,
    private scanService: ScanService,
    private cacheService: CacheService,
    private syncService: EventManagementSyncService,
  ) {
    this.subscriptions.push(this.settingsService.settings$.subscribe(settings => {
      this.settings = settings;
    }));
  }

  async ngOnInit(): Promise<void> {
    if (this.connectionService.isOffline$.getValue()){
      this.config = await this.cacheService.findOfflineVoucher<RedemptionConfig>('/config');
      console.log(this.config);
    }else {
      this.config = await this.httpCachedClient.getRaw<RedemptionConfig>('/api/redeemapp/config');
    }
  }

  /**
   * scan one single qr code and show the detail page afterwards
   */
  public async scanCode(): Promise<void> {
    await this.scanService.scanCode();
  }
  /**
   * Scan many vouchers and automatically try to redeem vouchers
   */
  public async permanentScan() {
    await this.uiFactory.dismissModal();
    let innerLoader;

    // if (this.connectionService.isOffline$.getValue()) {
    //   await this.uiManager.showNoInternetConnectionToast();
    //   return;
    // }

    await Haptics.impact({style: ImpactStyle.Medium});
    const loader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());

    try {
      setTimeout(async () => {
        await loader.dismiss();
      }, 200);

      const scanResult = await this.redemptionService.startScanning();

      if (scanResult) {
        innerLoader = await this.uiManager.showLoader('Wird eingelöst');

        if (this.connectionService.isOffline$.getValue()){
          const ticket: Partial<CachedVoucher> = await this.cacheService.findOfflineVoucher('/' + scanResult);
          if (!isArray(ticket)) {
            const voucher: Partial<CachedVoucher> = {
              voucherCode: ticket.voucherCode,
              curVoucherStatusId: ticket.curVoucherStatusId,
              customer: ticket.customer,
              eventDateId: ticket.eventDateId
            };
            if (voucher.curVoucherStatusId === 10) {
              await this.syncService.updateStorage([{
                voucherCode: voucher.voucherCode,
                attributeKey: '',
                action: 'redeem',
              }]);
              await this.cacheService.changeTicketStatus(voucher);
              voucher.curVoucherStatusId = 9;

              setTimeout(async () => {
                await this.uiManager.dismiss();
                await this.permanentScan();
              }, 1500);

              } else if (voucher.curVoucherStatusId === 9) {
              const error = await this.uiManager.redemptionFailedWithError(await this.translateService.get('redeemError.voucherFullyRedeemed').toPromise());
              error.onDidDismiss().then( async () => {
                await this.permanentScan();
              });
            }
          }
        } else {
          const voucher = await this.redemptionService.fetchVoucher(scanResult);
          await innerLoader.dismiss();

          await this.redemptionService.addCodeToLastSearchedHistory(voucher.Code, voucher.ArticleType);

          const fullRedeemResult = await this.redemptionService.redeemFull(this.config, voucher);

          if (fullRedeemResult.Success) {
            const toast = await this.uiFactory.build(UIType.TOAST, {
              message: 'Erfolgreich eingelöst: ' + voucher.Code,
              icon: AppIcon.checkmark,
              duration: 1500
            });
            await toast.present();
            toast.onDidDismiss().then(async _ => await this.permanentScan());

          } else {
            const modal = await this.uiManager.showFastRedemptionError(fullRedeemResult.Error, voucher);
            modal.onDidDismiss().then(async (event: any) => {
              if (event.data.repeatScan) {
                await this.permanentScan();
              }
            });
          }
        }
      }
    } catch (e) {
      if (e instanceof CameraUnavailableError) {
        await this.uiManager.showCameraUnavailable();
      } else if (e instanceof ScanningAbortedError) {
      } else {
        const alert = await this.uiManager.showVoucherNotFoundError(this.connectionService.isOffline$.getValue());
        alert.onDidDismiss().then(() => this.permanentScan());
      }
    } finally {
      await loader.dismiss();
      await innerLoader.dismiss();
    }
  }

  async navigateToSettings() {
    await this.uiFactory.dismissModal();
    await this.router.navigate([Route.SETTINGS]);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
