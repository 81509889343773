import {NgModule} from '@angular/core';
import {EventManagementOfflineListComponent} from './event-management-offline-list.component';
import {SharedModule} from '../../../../../shared-libs/lib-core/src/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {createTranslateLoader} from '../main/main.module';
import {HttpClient} from '@angular/common/http';
import {IonicModule} from '@ionic/angular';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    EventManagementOfflineListComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: EventManagementOfflineListComponent
      }
    ]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IonicModule,
    ScrollingModule
  ],
  exports: []
})

export class EventManagementOfflineListModule {
}
