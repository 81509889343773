import {Component, Input, OnInit} from '@angular/core';
import {CARD, LOYALITY, TICKET} from '../../../../../../../shared-libs/lib-core/src/models/status/product.constants';
import {VoucherStatus} from '../../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {
  AuthenticationService
} from '../../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {Subscription} from 'rxjs';
import {CachedVoucher} from '../../../../../../../shared-libs/lib-core/src/models/cachedVoucher.model';

export enum ProductType {
  VOUCHER = 'VOUCHER',
  TICKET = 'TICKET',
  CARD = 'CARD',
  LOYALITY = 'LOYALITY'
}

@Component({
  selector: 'app-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
})
export class ProductDisplayComponent implements OnInit {

  @Input()
  product: VoucherStatus | Partial<VoucherStatus>;

  @Input()
  cachedProduct: CachedVoucher;

  ProductType = ProductType;
  mappedType: ProductType;
  displayAmount: number;
  voucherSubscription: Subscription;

  constructor(
    public authService: AuthenticationService,
  ) {
  }

  private mapType(product: VoucherStatus | Partial<VoucherStatus>) {
    switch (product.ArticleType) {
      case TICKET: {
        this.mappedType = ProductType.TICKET;
        break;
      }
      case CARD: {
        this.mappedType = ProductType.CARD;
        break;
      }
      case LOYALITY: {
        this.mappedType = ProductType.LOYALITY;
        break;
      }
      default: {
        this.mappedType = ProductType.VOUCHER;
        break;
      }
    }
  }

  ngOnInit(): void {
    if (this.product) {
      this.displayAmount = this.product.CurrentAmount;
      this.mapType(this.product);
    }
    if (this.cachedProduct && !this.product) {
      this.product = {
        ArticleName: this.cachedProduct.articleName,
        ArticleType: '18',
        Code: this.cachedProduct.voucherCode,
        Customer: {
          Firstname: this.cachedProduct.customer.firstName,
          Lastname: this.cachedProduct.customer.lastName
        },
      };
      this.mapType(this.product);
    }
  }
}
