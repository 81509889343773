import {Component, OnDestroy, OnInit} from '@angular/core';
import {UIFactoryService, UIType} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {DarkModeService} from '../../../../../shared-libs/lib-core/src/services/dark-mode.service';
import {SettingsService} from '../../../../../shared-libs/lib-core/src/services/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {Environment} from '../../../../../shared-libs/lib-core/src/models/environment';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
import {AuthenticationService} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {Router} from '@angular/router';
import {CacheService} from '../../../../../shared-libs/lib-core/src/services/cache.service';
import {switchToStatusMode} from '../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {DefinedSettings, IonSelectValueChange} from '../../../../../shared-libs/lib-core/src/models/common';
import {Subscription} from 'rxjs';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {UIManagerService} from '../../../../../shared-libs/lib-core/src/services/ui-manager.service';

@Component({
  selector: 'page-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  public environment: Environment = environment;
  public definedSettings: DefinedSettings;

  private subscriptions: Subscription[] = [];

  public constructor(
    private settingsService: SettingsService,
    private darkModeService: DarkModeService,
    private translatorService: TranslateService,
    private storageService: StorageService,
    private cacheService: CacheService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private platform: Platform,
    private ionRouterOutlet: IonRouterOutlet,
    private uiFactory: UIFactoryService,
    private connectionService: ConnectionService,
    private uiManagerService: UIManagerService
  ) {

    this.subscriptions.push(this.settingsService.settings$.subscribe(settings => {
      this.definedSettings = settings;
    }));
  }

  async ngOnInit(): Promise<void> {
  }

  public async updateLanguage(newLanguage: IonSelectValueChange | any): Promise<void> {
    this.translatorService.use(newLanguage.detail.value);
    this.updateSettings('language', newLanguage.detail.value);
  }

  public async updateDarkMode(event) {
    this.darkModeService.onToggle(event);

    if (event.detail.checked) {
      await switchToStatusMode('Dark');
    } else {
      await switchToStatusMode('Light');
    }
    this.updateSettings('darkmode', !!event.detail.checked);
  }

  public async updateShowTicketRedemptions(event) {
    this.updateSettings('showTicketRedemptions', !!event.detail.checked);
  }

  public async updateQuickRedemptionTime(value: IonSelectValueChange | any) {
    this.updateSettings('timeQuickRedemption', value.detail.value);
  }

  public async updateRedemptionFeedbackTime(value: IonSelectValueChange | any) {
    this.updateSettings('timeSingleRedemption', value.detail.value);
  }

  public async updateAskForTicketCancelation(event) {
    this.updateSettings('askForCancellations', event.detail.checked);
  }

  public async updateLastSearchedHistory(event) {
    this.updateSettings('lastSearchVouchersValidForMs', event.detail.value);
  }

  public async updateShowReservationInformation(event) {
    this.updateSettings('showReservationInformation', !!event.detail.checked);
  }

  public async updateDisplayRelevantTickets(event) {
    this.updateSettings('displayRelevantTickets', event.detail.checked);
  }

  public async updateCacheOfflineEventsTickets(event) {
    this.updateSettings('cacheOfflineEvents', event.detail.checked);
  }

  public async updateOfflineMode(event) {
    this.updateSettings('offlineMode', event.detail.checked);
  }

  public async updateTimeBeforeOfflinePopup(event) {
    this.updateSettings('timeBeforeOfflinePopup', event.detail.value);
  }

  public updateSettings(property: keyof DefinedSettings, value: any) {
    if (this.settingsService.settings$.getValue()[property] !== value) {
      this.settingsService.settings$.next({
        ...this.settingsService.settings$.getValue(),
        [property]: value
      });
    }
  }

  public async syncCache() {
    if (this.settingsService.settings$.getValue().offlineMode) {
      if (this.connectionService.isOffline$.getValue()) {
        await this.uiManagerService.showNoInternetConnectionToast();
      }
      this.cacheService.synchronizeCache$.next(true);
    }
  }

  public async resetApp() {
    const alert = await this.uiFactory.build(UIType.ALERT, {
      header: await this.translatorService.get('common.confirm').toPromise(),
      message: await this.translatorService.get('common.deleteHint').toPromise(),
      buttons: [{
        text: await this.translatorService.get('common.cancelBtn').toPromise(),
        role: 'cancel',
      },
        {
          text: await this.translatorService.get('common.confirmBtn').toPromise(),
          handler: () => {
            this.clearStorage();
          }
        }]
    });

    await alert.present();
  }

  public async clearAppCache() {
    try {
      const alert = await this.uiFactory.build(UIType.ALERT, {
        header: await this.translatorService.get('common.confirm').toPromise(),
        buttons: [{
          text: await this.translatorService.get('common.cancelBtn').toPromise(),
          role: 'cancel',
        },
          {
            text: await this.translatorService.get('common.confirmBtn').toPromise(),
            handler: async () => {
              await this.storageService.remove(StorageKey.CACHE);
              await this.storageService.remove(StorageKey.HISTORY);
              await this.storageService.remove(StorageKey.FAVOURITES);
              await this.storageService.remove(StorageKey.OFFLINE);

              this.cacheService.cache$.next([]);
              this.cacheService.searchHistory$.next([]);

              const toast = await this.showCacheClearedAlert();

              await toast.present();
            }
          }
        ]
      });

      await alert.present();

    } catch (e) {
    }
  }

  private async clearStorage() {
    await this.storageService.clearStorage();

    await this.uiFactory.build(UIType.TOAST, {
      message: await this.translatorService.get('common.deleteNote').toPromise(),
      duration: 1500
    }).then(toast => toast.present());

    await this.authenticationService.logout().then(() => this.router.navigate(['login']));
  }

  private async showCacheClearedAlert(): Promise<HTMLIonToastElement> {
    return this.uiFactory.build(UIType.TOAST, {
      message: this.translatorService.instant('common.cacheDeleted'),
      duration: 2000
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
