import {Component, OnInit} from '@angular/core';
import {CacheConfig, CacheEntry, CacheService} from '../../../../../shared-libs/lib-core/src/services/cache.service';
import {EventDate} from '../event-management/event-management.model';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
@Component({
  selector: 'app-event-management-offline-list',
  templateUrl: './event-management-offline-list.component.html',
  styleUrls: ['./event-management-offline-list.component.scss'],
})
export class EventManagementOfflineListComponent implements OnInit {
  public size: number;
  public cachedEvents: EventDate[] = [];
  constructor(private cacheService: CacheService, private storageService: StorageService) { }

  async ngOnInit() {
    this.size = Math.ceil(((new Blob([(await this.storageService.get<any>(StorageKey.OFFLINE, true)).value]).size / 1048576) / 5) * 100);
    const offlineCache: CacheEntry[] = this.cacheService.offlineVouchers$.getValue();
    const cacheConfig = await this.cacheService.findOfflineVoucher<CacheConfig>('/cacheConfig');
    if (cacheConfig?.cachedIds.length){
      this.cachedEvents = offlineCache.find(c => c.uri.includes('/eventDates')).data;
    }
  }

  public ticketCount(eventDateId: number): number {
    return this.cacheService.offlineVouchers$.getValue().filter(c => c.data.eventDateId === eventDateId).length;
  }

}
