import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {SystemService} from './system.service';
import {CacheEntry, CacheService} from './cache.service';
import {HTTPRequestError} from '../errors/http.error';
import {SettingsService} from './settings.service';
import {StorageKey, StorageService} from './storage.service';
import {EventDate, Event, Ticket} from '../../../../src/app/components/pages/event-management/event-management.model';
import {RedemptionConfig} from '../models/redemptionConfig';

@Injectable({
  providedIn: 'root'
})
export class HttpCachedClient implements OnDestroy {

  private systemSubscription: Subscription;
  public unauthorizedSubject: Subject<{}> = new Subject();
  public uri = '';

  public constructor(
    protected http: HttpClient,
    private cacheService: CacheService,
    private system: SystemService,
    private systemService: SystemService,
    private settingsService: SettingsService,
    private storageService: StorageService
  ) {

    this.systemSubscription = this.systemService.currentSystem$.subscribe(sys => this.uri = sys);
  }

  /**
   * Intercepting Requests and storing them to the local Storage Provider (either LS, SharedPreferences or UserDefaults)
   * @param endpoint stored as lookup
   * @param doCacheLookup check cache for already sent requests
   * @param doCacheResponse store the response into cache
   * @throws {HTTPRequestError}
   */
  public async get<T>(endpoint: string, doCacheLookup: boolean = false, doCacheResponse: boolean = false): Promise<T> {

    endpoint += (endpoint.includes('?') ? '&' : '?') + `language=${ this.settingsService.settings$.getValue().language}`;
    if (doCacheLookup) {
      const cached = await this.cacheService.find<T>(endpoint);

      if (cached) {
        return cached as T;
      } else {
        return await this.fetch<T>(endpoint, doCacheResponse);
      }
    } else {
      return await this.fetch<T>(endpoint, doCacheResponse);
    }
  }

  public async storeForOffline<T>(eventDateId: number){
    try {
      const tickets: Ticket[] = await this.getRaw(`/api/events/dates/${eventDateId}/tickets`);
      const config: RedemptionConfig = await this.getRaw('/api/redeemapp/config');
      const eventDate = await this.getRaw<EventDate>(`/api/events/dates/${eventDateId}`);
      const cachedEventDates = await this.cacheService.findOfflineVoucher<Array<EventDate>>('/eventDates') ?? [];
      let cache = await this.storageService.get<Array<CacheEntry>>(StorageKey.OFFLINE) ?? [];
      if (!cachedEventDates.find(e => e.id === eventDateId)){
        cachedEventDates.push(eventDate);
      }
      tickets.map((ticket) => {
        Object.keys(ticket).forEach((key) => {
          if (key !== 'voucherCode' && key !== 'curVoucherStatusId' && key !== 'customer') {
            delete ticket[key];
          }
          ticket['eventDateId'] = eventDateId;
        });

        cache = this.cacheService.offlineStore(cache, this.cacheService.buildLookupKey('/' + ticket.voucherCode), ticket);
      });

      cache = this.cacheService.offlineStore(cache, this.cacheService.buildLookupKey('/eventDates'), cachedEventDates);
      cache = this.cacheService.offlineStore(cache, this.cacheService.buildLookupKey('/config') , config);
      return await this.storageService.set<Array<CacheEntry>>(StorageKey.OFFLINE, cache);
    } catch (e) {
      throw new HTTPRequestError('HTTPRequest Error: ' + e.error);
    }
  }

  /**
   * wrapper for requests that should never be cached
   * @throws {HTTPRequestError}
   */
  public async getRaw<T>(endpoint: string): Promise<T> {
    return await this.get(endpoint, false, false);
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async post<T>(endpoint: string, body: any, options?: HttpHeaders): Promise<T> {
    try {
      return await this.http.post<T>(this.buildRequest(endpoint), body, {...options}).toPromise() as any;
    } catch (e) {
      throw new HTTPRequestError('could not forward post request: ' + e.error);
    }
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async put<T>(endpoint: string, body: any, options?: HttpHeaders): Promise<T> {
    try {
      return await this.http.put<T>(this.buildRequest(endpoint), body, {...options}).toPromise() as any;
    } catch (e) {
      throw new HTTPRequestError('could not forward post request: ' + e.error);
    }
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async delete<T>(endpoint: string): Promise<T> {
    try {
      return await this.http.delete(this.buildRequest(endpoint)).toPromise() as T;
    } catch (e) {
      throw new HTTPRequestError('could not forward delete request');
    }
  }

  /**
   * @throws {HTTPRequestError}
   */
  private async fetch<T>(endpoint: string, storeToCache: boolean) {
    try {
      return await this.http.get(this.buildRequest(endpoint))
        .pipe(
          tap(response => storeToCache ? this.cacheService.store(endpoint, response) : null),
          map(response => response)).toPromise() as T;
    } catch (e) {
      throw new HTTPRequestError('HTTPRequest Error: ' + e.error);
    }
  }

  private buildRequest(endpoint: string) {
    return this.uri + endpoint;
  }

  ngOnDestroy(): void {
    this.systemSubscription.unsubscribe();
  }
}
