import {Pipe, PipeTransform} from '@angular/core';
import {
  VOUCHERCODE_ALREADY_EXPIRED, VOUCHERCODE_CANCELED, VOUCHERCODE_FULLY_REDEEMED, VOUCHERCODE_NOT_ACTIVATED,
  VOUCHERCODE_NOT_FOUND, VOUCHERCODE_NOT_PAYED_YET, VOUCHERCODE_NOT_VALID_FOR_THIS_RATE_CODE,
  VOUCHERCODE_NOT_VALID_FOR_THIS_STATION,
  VOUCHERCODE_TYPE_NOT_ALLOWED,
  VOUCHERCODE_VALID
} from '../../models/status/voucher.status.constants';
import {TranslateService} from '@ngx-translate/core';
import {VoucherStatus} from '../../models/voucher.status';

@Pipe(
  {
    name: 'invoucherstate'
  }
)
export class VoucherStatePipe implements PipeTransform {

  public constructor(private t: TranslateService) {

  }

  public async transform(value: VoucherStatus, getShort: boolean = false): Promise<string> {
    if (value.StatusDetails) {
      if (getShort) {
        switch (value.StatusDetails.code){
          case 'ERR_REDEMPTION_VALID_UNTIL_DENY':
            return await this.t.get('voucherState.alreadyExpired').toPromise();
          case 'ERR_VOUCHER_LOCKED':
            return await this.t.get('voucherState.voucherLocked').toPromise();
          case 'ERR_ORDER_LOCKED':
            return await this.t.get('voucherState.orderLocked').toPromise();
          case 'ERR_VOUCHER_NOT_PAID_YET':
            return await this.t.get('voucherState.notPayedYet').toPromise();
        }
      }
      return value.StatusDetails.message;
    }
    switch (value.Status) {

      case VOUCHERCODE_VALID:
        if (value.CurrentAmount === value.InitialAmount._) {
          return await this.t.get('voucherState.not_redeemed').toPromise();
        } else {
          return await this.t.get('voucherState.partly_redeemed').toPromise();
        }

      case VOUCHERCODE_FULLY_REDEEMED:
        return await this.t.get('voucherState.fullyRedeemed').toPromise();

      case VOUCHERCODE_ALREADY_EXPIRED:
        return await this.t.get('voucherState.alreadyExpired').toPromise();

      case VOUCHERCODE_CANCELED:
        return await this.t.get('voucherState.canceled').toPromise();

      case VOUCHERCODE_NOT_ACTIVATED:
        return await this.t.get('voucherState.notActivated').toPromise();

      case VOUCHERCODE_NOT_FOUND:
        return await this.t.get('voucherState.notFound').toPromise();

      case VOUCHERCODE_TYPE_NOT_ALLOWED:
        return await this.t.get('voucherState.typeNotAllowed').toPromise();

      case VOUCHERCODE_NOT_PAYED_YET:
        return await this.t.get('voucherState.notPayedYet').toPromise();

      case VOUCHERCODE_NOT_VALID_FOR_THIS_STATION:
        return await this.t.get('voucherState.notValidForThisStation').toPromise();

      case VOUCHERCODE_NOT_VALID_FOR_THIS_RATE_CODE:
        return await this.t.get('voucherState.notValidForThisRateCode').toPromise();

      default:
        return await this.t.get('voucherState.default').toPromise();
    }
  }

}
